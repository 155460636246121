import Peer from "peerjs";
import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { FileRevice } from "./file-recive";
import { FileToUpload } from "./file-upload";

export const State = {
  starting: "starting",
  waiting: "waiting",
  connected: "connected",
  disconnected: "disconnected",
  error: "error",
};
export const Events = {
  REMOVE_FILE: "REMOVE_FILE",
  UPDATE_DOWNLOAD_PROGRESS: "UPDATE_DOWNLOAD_PROGRESS",
  UPLOAD_FILES: "UPLOAD_FILES",
  FILES_RECIVED: "RECIVE_FILES",
  DISCONNECTED: "DISCONNECTED",
  DEVICE_INFO: "DEVICE_INFO",
};

class PeerBuilder {
  constructor({ callId, fixedId }) {
    this.callId = callId;
    this.peer = new Peer(fixedId, {
      host: "https://zconnect-back-peer.herokuapp.com".replace("https://", ""),
      // port: 9000,
      secure: "https://zconnect-back-peer.herokuapp.com".includes("https"),
      path: "myapp",
      config: {
        iceServers: [
          { urls: "stun:stun.l.google.com:19302" },
          { urls: "stun:stun1.l.google.com:19302" },
          { urls: "stun:stun2.l.google.com:19302" },
          { urls: "stun:stun3.l.google.com:19302" },
          { urls: "stun:stun4.l.google.com:19302" },
        ],
      },
    });
    this.onOpen = () => false;
    this.onEvent = () => false;
    this.onData = () => false;
    this.onStatusChange = () => false;
  }

  addOnOpen(fn) {
    this.onOpen = fn;
    return this;
  }

  addOnEvent(fn) {
    this.onEvent = fn;
    return this;
  }
  addOnStatusChange(fn) {
    this.onStatusChange = fn;
    return this;
  }

  call() {
    // Se ele tiver, invite callId undefeidn
    if (this.callId) {
      const conn = this.peer.connect(this.callId, {
        reliable: true,
      });
      this.registerCon(conn);
    }
  }

  disconnect() {
    try {
      this.onStatusChange(State.disconnected);
      if (this.conn) this.conn.close();
    } catch (error) {}
  }

  sendData(data) {
    console.log("Send data", data, this.conn);
    if (this.conn) this.conn.send(data);
  }

  registerCon(conn) {
    this.conn = conn;
    conn.on("open", () => {
      this.onStatusChange(State.connected);
    });
    conn.on("close", () => {
      console.log("Finish");
      this.onStatusChange(State.disconnected);
    });
    conn.on("data", (data) => {
      this.onEvent(data);
    });
    conn.on("error", (e) => {
      console.log("error", e);
      throw new Error(e);
    });
  }

  build() {
    this.onStatusChange(State.starting);

    this.peer.on("open", (id) => {
      this.onOpen(id);
      this.onStatusChange(State.waiting);
      this.call();
    });

    this.peer.on("connection", (conn) => this.registerCon(conn));
    this.peer.on("disconnected", () => console.log("Disconnected"));
    this.peer.on("error", (e) => console.log("Error", e));
    this.peer.on("close", () => console.log("Cloooose"));
    // this.peer.on("error");

    return this;
  }
}

class Room {
  constructor({ roomId, setState, setId, filesUpdated }) {
    this.peer = new PeerBuilder({
      callId: roomId,
    })
      .addOnOpen(setId)
      .addOnStatusChange(setState)
      .addOnEvent((e) => this.onEvent(e))
      //   .addOnStream(setRemoteStream)
      .build();
    this.myFiles = [];
    this.files = [];
    this.removeFiles = [];
    this.filesUpdated = filesUpdated;
  }

  onEvent(event) {
    const processEvents = {
      [Events.UPLOAD_FILES]: (e) => this.onReciveFile(e.data),
      // [Events.FILES_RECIVED]: (e) => onFilesRecived(e.data),
      [Events.DISCONNECTED]: () => this.onDisconnected(),
      [Events.REMOVE_FILE]: (e) => this.onRemoveFile(e.data),
      [Events.UPDATE_DOWNLOAD_PROGRESS]: (e) =>
        this.updateDownloadProgress(e.data),
    };

    // const onDisconnected = () => {
    //   if (peer.current) peer.current.disconnect();
    // };
    // const onUploadFiles = (file) => {
    //   // setFiles((f) => {
    //   //   if((f.find((f) => f.fileId == file.id) ? file : f))
    //   // });
    //   sendEvent(
    //     Events.FILES_RECIVED,
    //     files.map((f) => f.filename)
    //   );
    // };

    // const onRemoveFile = (fileId) => {
    //   setFiles((files) => files.filter((f) => f.fileId !== fileId));
    // };

    // const onFilesRecived = (filesNames) =>
    //   setMyFiles((myFiles) =>
    //     myFiles.map((file) =>
    //       filesNames.includes(file.filename)
    //         ? {
    //             ...file,
    //             recived: true,
    //           }
    //         : file
    //     )
    //   );

    processEvents[event.type] && processEvents[event.type](event);
  }

  onDisconnected() {
    this.peer.disconnect();
  }

  updateDownloadProgress({ progress, fileId }) {
    const file = this.myFiles.find((f) => f.fileId == fileId);
    if (file) file.updateDownloadProgress(progress);
    this.onFilesUpdate();
  }

  onFilesUpdate() {
    this.filesUpdated(
      this.myFiles.map((f) => f.getInfo()),
      this.files.map((f) => f.getInfo())
    );
  }

  onRemoveFile(fileId) {
    this.files = this.files.filter((f) => f.fileId !== fileId);
    this.removeFiles.push(fileId);
    this.onFilesUpdate();
  }

  onFileChange(fileInfo) {
    //
    if (this.myFiles.find((f) => f.fileId == fileInfo.fileId)) {
      this.peer.sendData({
        type: Events.UPLOAD_FILES,
        data: fileInfo,
      });
      this.onFilesUpdate();
    }
  }

  onUploadFiles(files) {
    const _files = files.map(
      (f) => new FileToUpload(f, (fileInfo) => this.onFileChange(fileInfo))
    );

    this.myFiles = this.myFiles.concat(_files);

    _files.map((f) => f.upload());
  }
  removeFile(fileId) {
    const file = this.myFiles.find((f) => f.fileId == fileId);

    this.myFiles = this.myFiles.filter((f) => f.fileId !== fileId);
    this.removeFiles.push(fileId);
    file.stop();

    this.peer.sendData({
      type: Events.REMOVE_FILE,
      data: fileId,
    });
    this.onFilesUpdate();
  }

  onReciveFile(fileInfo) {
    let _file = this.files.find((f) => f.fileId == fileInfo.fileId);

    if (_file) {
      _file.reciveBytes(fileInfo);
    } else if (!this.removeFiles.includes(fileInfo.fileId)) {
      _file = new FileRevice(fileInfo);
      this.files.push(_file);
    }
    if (_file) {
      const { progress } = _file.getInfo();
      this.peer.sendData({
        type: Events.UPDATE_DOWNLOAD_PROGRESS,
        data: {
          progress,
          fileId: fileInfo.fileId,
        },
      });
    }
    this.onFilesUpdate();
  }

  logout() {
    //   // maneira mais simples de deslogar
    this.peer.sendData({ type: Events.DISCONNECTED });
    window.location.href = window.location.origin;
  }
}

export function usePeer({ roomId, fixedId }) {
  const [id, setId] = useState(null);
  const [state, setState] = useState(null);
  const [files, setFiles] = useState([]);
  const [myFiles, setMyFiles] = useState([]);
  const room = useRef(null);

  // const logout = () => {
  //   // maneira mais simples de deslogar
  //   sendEvent(Events.DISCONNECTED);
  //   window.location.href = window.location.origin;
  // };

  useEffect(() => {
    room.current = new Room({
      roomId,
      filesUpdated: (myFiles, files) => {
        setFiles(files);
        setMyFiles(myFiles);
      },
      setId,
      setState,
    });
  }, []);

  const uploadFiles = (files) => {
    if (room.current) {
      room.current.onUploadFiles(files);
    }
  };
  const removeFile = (fileId) => {
    if (room.current) {
      room.current.removeFile(fileId);
    }
  };
  const logout = () => {
    if (room.current) {
      room.current.logout();
    }
  };

  return {
    id,
    state,
    files,
    myFiles,
    uploadFiles,
    removeFile,
    logout,
    // sendEvent,
    // sendFiles,
    // error,
  };
}
