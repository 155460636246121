import React, { useEffect, useState } from "react";
import "./style.scss";
import ProgressBar from "../ProgressBar";
const AWrapper = ({ file, children }) =>
  file.url ? (
    <a href={file.url} download={file.filename} className="file-content">
      {children}
    </a>
  ) : (
    <div className="file-content">{children}</div>
  );

export default ({ file, myFiles, onRemoveFile }) => {
  const [iconImg, setIconImg] = useState("/icons/file.svg");
  const [iconColor, setIconColor] = useState("#496DFB");

  useEffect(() => {
    const icons = [
      { name: "image", icon: "/icons/file-image.svg", color: "#274AD2" },
      { name: "mp3", icon: "/icons/file-video.svg", color: "#F0B23E" },
      { name: "mp4", icon: "/icons/file-video.svg", color: "#F0B23E" },
      { name: ".mov", icon: "/icons/file-video.svg", color: "#F0B23E" },
    ];

    const icon = icons.find((i) => file.filetype.includes(i.name));
    const iconImg = icon ? icon.icon : "/icons/file.svg";
    const iconColor = icon ? icon.color : "#496DFB";

    setIconImg(iconImg);
    setIconColor(iconColor);
  }, []);

  return (
    <AWrapper file={file}>
      <div className="file-icon">
        <img src={iconImg} />
      </div>
      <div
        className="file-content-info"
        style={{
          opacity: !myFiles && file.progress != 100 ? 0.7 : 1,
        }}
      >
        <div className="file-name font-1" title={file.filename}>
          {file.filename}
        </div>
        <div className="file-size font-1">{file.fileSize}</div>
        {file.progress != 100 && (
          <ProgressBar color={iconColor} progress={file.progress} />
        )}
      </div>
      {myFiles && (
        <div className="file-tool" onClick={onRemoveFile}>
          {/* <div>
            <img src="/icons/pause.svg" />
          </div> */}
          <img src="/icons/remove.svg" />
        </div>
      )}
      {!myFiles && (
        <div
          className="file-tool"
          style={{
            opacity: !myFiles && file.progress != 100 ? 0.7 : 1,
          }}
        >
          <div>
            <img src="/icons/download.svg" />
          </div>
        </div>
      )}
    </AWrapper>
  );
};
