import React from "react";
import RenderFile from "./RenderFile";
import "./style.scss";

export default ({ title, files, myFiles, noFilesText, onRemoveFile }) => {
  return (
    <div className="file-box">
      <div className="title font-3">{title}</div>
      <div
        className={`box ${myFiles ? "my-files" : ""} ${
          files.length == 0 ? "no-files" : ""
        }`}
      >
        {files.map((f) => (
          <RenderFile
            file={f}
            key={f.fileId}
            myFiles={myFiles}
            onRemoveFile={() => onRemoveFile(f.fileId)}
          />
        ))}
        {files.length == 0 && (
          <div className="no-files-text  font-3 ">{noFilesText}</div>
        )}
      </div>
    </div>
  );
};
