import QRCode from "qrcode.react";
import ClipLoader from "react-spinners/BeatLoader";

import { CopyToClipboard } from "react-copy-to-clipboard";

import "./styles.scss";

export default ({ id }) => {
  const handleArrouClick = () => {
    if (window.scrollBy)
      window.scrollBy({
        top: 20000,
        behavior: "smooth",
      });
  };

  const url = `${window.location.origin}/room/${id}`;
  return (
    <div id="home-page">
      <div className="landing-container df bg-white">
        <img className="icon" src="/icons/files_group.svg" alt="Ícone" />
        <img className="title" src="/icons/logo_blue.svg" alt="Logo" />
        {/* <div className="title">zdrop</div> */}
        <div className="subtitle">
          Transfira arquivos para
          <br /> qualquer dispositivo
        </div>
        <div className="instructions">
          Compartilhe arquivos de um dispositivo para outro, <br />
          <b>simples e sem complicação!</b>
        </div>

        <img
          className="arrow"
          src="/icons/arrow_down.svg"
          alt="Deslizar"
          onClick={handleArrouClick}
        />
        <div className="powered-by font-1">
          powered by{" "}
          <a href="https://zeero.tech/" className="zeero" target="_blank">
            Zeero
          </a>
        </div>
      </div>
      <div className="qr-container df bg-blue">
        <div
          className="qr-code"
          style={{
            backgroundImage: 'url("/img/qr-code-back.png")',
          }}
        >
          {id && (
            <QRCode
              bgColor="#051D7A"
              fgColor="#F9F9F9"
              size={180}
              value={url}
            />
          )}

          {!id && <ClipLoader size={25} color="#F9F9F9" />}
        </div>
        <div className="instructions">
          <p>
            <b>Escaneie o QR Code</b> usando a <b>câmera</b> do <br />
            dispositivo o qual queira trocar arquivos.
          </p>
          <p>
            Você entrará na sua sala, onde poderá <br />
            <b>enviar e receber</b> o que quiser
          </p>
          <p>
            <b>Gratuito e 100% seguro.</b> <br />
            Seus arquivos não ficarão salvos no Zdrop.
          </p>
        </div>
        {/* <CopyToClipboard
          text={url}
          //   onCopy={() => this.setState({ copied: true })}
        >
          <button
            className="btn btn-secondary lg"
            disabled={!id}
            style={{ opacity: !id ? 0.6 : 1 }}
          >
            Copiar link do compartilhamento
          </button>
        </CopyToClipboard> */}
      </div>
    </div>
  );
};
