import React, { useEffect, useState } from "react";
import "./style.scss";

export default ({ children, open, onHide = () => false }) => {
  useEffect(() => {
    if (open && !document.body.className.includes(" no-scroll")) {
      document.body.className += document.body.className + " no-scroll";
    } else {
      document.body.className = document.body.className.replace(
        " no-scroll",
        ""
      );
    }
  }, [open]);

  if (!open) return null;

  return (
    <div className="modal">
      <div className="overlay" onClick={onHide}></div>
      <div className="card">{children}</div>
    </div>
  );
};
