//   fileId: this.fileId,
//   filename: this.filename,
//   filetype: this.filetype,
//   fileSize: this.fileSize,
//   bytes,
//   progress: Math.floor((this.offset / this.size) * 100),

export class FileRevice {
  constructor({
    fileId,
    filename,
    filetype,
    fileSize,
    bytes,
    progressPart,
    fileSizeInt,
  }) {
    this.fileId = fileId;
    this.filename = filename;
    this.filetype = filetype;
    this.fileSize = fileSize;
    this.fileSizeInt = fileSizeInt;
    this.bytes = bytes ? [bytes] : [];
    this.progress = progressPart;
  }

  getInfo() {
    const {
      fileId,
      filename,
      filetype,
      fileSize,
      url,
      progress,
      fileSizeInt,
    } = this;
    return {
      fileId,
      filename,
      filetype,
      fileSize,
      url,
      progress,
      fileSizeInt,
    };
  }

  reciveBytes({ progressPart, bytes }) {
    if (bytes) this.bytes.push(bytes);
    this.progress = progressPart;
    if (this.progress >= 100)
      this.url = window.URL.createObjectURL(
        new Blob(this.bytes, { type: this.filetype })
      );
  }
}
