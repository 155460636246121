let guid = () => {
  let s4 = () => {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  };
  //return id of format 'aaaaaaaa'-'aaaa'-'aaaa'-'aaaa'-'aaaaaaaaaaaa'
  return (
    s4() +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    s4() +
    s4()
  );
};

function toFileSize(bytes, si = false, dp = 1) {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + " B";
  }

  const units = si
    ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (
    Math.round(Math.abs(bytes) * r) / r >= thresh &&
    u < units.length - 1
  );

  return bytes.toFixed(dp) + " " + units[u];
}

const chunkSize = 625000;

export class FileToUpload {
  constructor(inputFile, sendFile) {
    this.fileId = guid();
    this.__file = inputFile;
    this.__continue = true;
    this.filename = inputFile.name;
    this.filetype = inputFile.type;
    this.offset = 0;
    this.size = inputFile.size;
    this.fileSize = toFileSize(inputFile.size, true, 0);

    this.fileReader = new FileReader();
    this.sendFile = sendFile;
    this.fileReader.addEventListener("load", (e) =>
      this.readPart(e.target.result)
    );
    setTimeout(() => {
      this.sendFile(this.getInfo());
    }, 0);
  }

  readPart(result) {
    this.offset += result.byteLength;
    this.uploadPart(result);
    // sendPartOfFile({
    //   bytes: e.target.result,
    //   offset,
    // });
    // sendProgress.value = offset;
  }

  getInfo(bytes) {
    return {
      fileId: this.fileId,
      filename: this.filename,
      filetype: this.filetype,
      fileSize: this.fileSize,
      fileSizeInt: this.size,
      bytes,
      progress: this.progress,
      progressPart: Math.floor((this.offset / this.size) * 100),
    };
  }

  updateDownloadProgress(progress) {
    this.progress = progress;

    if (this.offset < this.__file.size && this.__continue) {
      setTimeout(() => {
        this.uploadSlice(this.offset);
      }, 0);
    }
  }

  uploadPart(bytes) {
    //TO.DO SEND TO
    this.sendFile(this.getInfo(bytes));
  }

  stop() {
    this.__continue = false;
  }

  uploadSlice(o = 0) {
    const slice = this.__file.slice(this.offset, o + chunkSize);
    this.fileReader.readAsArrayBuffer(slice);
  }

  upload() {
    this.uploadSlice(0);
  }
}
