import React from "react";
import "./style.scss";
import Dropzone from "react-dropzone";

let guid = () => {
  let s4 = () => {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  };
  //return id of format 'aaaaaaaa'-'aaaa'-'aaaa'-'aaaa'-'aaaaaaaaaaaa'
  return (
    s4() +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    s4() +
    s4()
  );
};

function toFileSize(bytes, si = false, dp = 1) {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + " B";
  }

  const units = si
    ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (
    Math.round(Math.abs(bytes) * r) / r >= thresh &&
    u < units.length - 1
  );

  return bytes.toFixed(dp) + " " + units[u];
}

export default ({ uploadFiles, disabled }) => (
  <Dropzone onDrop={uploadFiles}>
    {({ getRootProps, getInputProps }) => (
      <div
        className="upload-area"
        {...getRootProps()}
        style={{
          opacity: disabled ? 0.5 : 1,
        }}
      >
        <input
          className=""
          style={{ opacity: 0 }}
          {...getInputProps()}
          disabled={disabled}
          // accept="image/x-png,image/gif,image/jpeg"
        />
        <img src="/icons/upload.svg" />
        <div className="text">Coloque os arquivos aqui</div>
      </div>
    )}
  </Dropzone>
);
