import React from "react";
import "./index.scss";
export default ({ color, progress }) => {
  return (
    <div class="linear-progress-material small">
      <div
        class="bar bar1"
        style={{
          background: color,
          width: `${progress}%`,
        }}
      ></div>
    </div>
  );
};
