import logo from "./logo.svg";
import { BrowserRouter, Route } from "react-router-dom";
import Index from "./pages/index";
import Room from "./pages/room";

import "./global.scss";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

Sentry.init({
  dsn:
    "https://8ddc9a41f65b47adbfa29920bb98a56c@o372304.ingest.sentry.io/5627781",
  integrations: [new Integrations.BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 0.1,
});

function App() {
  return (
    <>
      <BrowserRouter>
        <Route exact path="/" component={Index} />
        <Route exact path="/room/:key" component={Room} />
      </BrowserRouter>
    </>
  );
}

export default App;
