import { State, usePeer } from "../hook/usePeer/usePeer";
import Invite from "./Invite";
import Room from "./Room/index";

export default ({ roomId, fixedId }) => {
  const {
    id,
    state,
    files,
    myFiles,
    uploadFiles,
    removeFile,
    logout,
  } = usePeer({
    roomId,
    fixedId,
  });

  if (!roomId && [State.starting, State.waiting].includes(state))
    return <Invite id={id} />;

  return (
    <Room
      {...{
        id,
        state,
        files,
        myFiles,
        uploadFiles,
        removeFile,
        logout,
        roomName: (roomId || id || "").slice(0, 5).toUpperCase(),
      }}
    />
  );
};
