import { useState } from "react";
import { Events, State, usePeer } from "../../hook/usePeer/usePeer";
import FileBox from "../FileBox/index";
import Modal from "../Modal";
import UploadFilesInput from "../UploadFilesInput/index";
import "./style.scss";

// {
//   fileId: guid(),
//   file: blob,
//   filename: file.name,
//   filetype: file.type,
//   fileSize: toFileSize(file.size, true, 0),
//   progress: 0
// };

export default ({
  id,
  state,
  files,
  myFiles,
  uploadFiles,
  removeFile,
  roomName,
  logout,
}) => {
  const [removeItemModal, setRemoveItemModal] = useState(false);
  const [logoutModal, setLogoutModal] = useState(false);
  // quando clicar em excluir: "" - Sim, excluir (botão ghost) / Cancelar (botão azul)

  // Quando clicar em "sair": "Tem certeza que deseja sair da sala?" - Sim, sair (botão ghost) / Continuar na sala (botão azul)
  return (
    <div className="room">
      <Modal open={removeItemModal} onHide={() => setRemoveItemModal(false)}>
        <b className="font-3">Tem certeza que deseja excluir o arquivo?</b>
        <div className="btn-footer">
          <button
            className="btn btn-default"
            onClick={() => {
              removeFile(removeItemModal);
              // sendEvent(Events.REMOVE_FILE, removeItemModal);
              setRemoveItemModal(false);
            }}
          >
            Sim, excluir
          </button>
          <button
            className="btn btn-primary"
            onClick={() => setRemoveItemModal(false)}
          >
            Cancelar
          </button>
        </div>
      </Modal>

      <Modal open={logoutModal} onHide={() => setLogoutModal(false)}>
        <b className="font-3">Tem certeza que deseja sair da sala?</b>
        <div className="btn-footer">
          <button
            className="btn btn-default"
            onClick={() => {
              logout();
            }}
          >
            Sim, sair
          </button>
          <button
            className="btn btn-primary"
            onClick={() => setLogoutModal(false)}
          >
            Cancelar
          </button>
        </div>
      </Modal>

      {/* {id}: {state} */}
      <div className="logout font-3">
        <div className="btn-logout" onClick={() => setLogoutModal(true)}>
          Sair
          <img src="/icons/logout.svg" />
        </div>
      </div>

      <div className="header">
        <img src="/icons/logo.svg" className="logo" />
        <div className="room-name font-2">
          Sala #<b>{roomName}</b>
        </div>
      </div>

      {state === State.connected && (
        <div className="text font-2">
          <b>2 dispositivos</b> estão conectados
        </div>
      )}
      {(state === State.waiting || state === State.disconnected) && (
        <div className="text font-2">Aguardando outro dispositivo</div>
      )}

      {state === State.starting && (
        <div className="text font-2">Conectando...</div>
      )}

      <div className="box">
        <div className="title font-3">Adicione seus arquivos aqui</div>
        <UploadFilesInput
          disabled={state !== State.connected}
          uploadFiles={uploadFiles}
        />
        <div className="filex-boxes">
          <FileBox
            title={"Arquivos enviados"}
            noFilesText="Você ainda não enviou arquivos"
            files={myFiles}
            myFiles
            onRemoveFile={(fileId) => {
              setRemoveItemModal(fileId);
            }}
          />
          <div className="space"></div>
          <FileBox
            title={"Arquivos recebidos"}
            noFilesText="Você ainda não recebeu arquivos"
            files={files}
          />
        </div>
        <div className="powered-by font-1">
          powered by{" "}
          <a href="https://zeero.tech/" className="zeero" target="_blank">
            Zeero
          </a>
        </div>
      </div>
    </div>
  );
};
